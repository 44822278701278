import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  Grid,
  CircularProgress,
  Link,
  CardMedia
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';


class UploadFile extends React.Component{
  state={
    loading:false,
  }
	render(){
		const {classes,handleFileUpload,handleFileDelete,state,className,name} =this.props;
    const style=`${classes.container} ${className}`;
		return(
			<Grid container  className={style} > 
			{this.state.loading?
  				<CircularProgress className={classes.loading_file} size={25} />
  				:
  				<Grid container className={classes.imageContainer}   >
  				{state[name].path?
  					 <CardMedia
              image={state[name].path}
              title={state[name].name}
              className={classes.image}>
  						<Link className={classes.innerLinear} onClick={()=>handleFileDelete((v)=>this.setState({loading:v}))} >
                <DeleteIcon color="secondary" className={classes.closeIcon} size={30}/>
              </Link>
  					</CardMedia>
  					:
  					<input
  					  type="file"
					    onChange={(event)=>handleFileUpload((v)=>this.setState({loading:v}),event)}
              className={classes.input}
					 />
					}
				</Grid>
			}
			</Grid>
		);
	}
}

const styles = {
  container:{
    justifyContent : 'center',
    minHeight: 300,
    minWidth: 300,
    marginBottom:30,
  },
	image:{
      width:300,
  		height:300,
      backgroundSize : 'cover',
      backgroundPosition :'center center',
  	},
  	loading_file:{
  		alignSelf:"center",
  		
  	},
  	imageContainer:{
  		alignItems:"center",
  		justifyContent:"center",
  		width:300,
  		height:300,
  		borderRadius:10,
  		
  		backgroundColor:"rgba(0,0,0,.05)",
  		position:"relative",
  		overflow : 'hidden'
  	},
  	innerLinear:{
  		borderRadius:10,
  		width:300,
  		position : 'absolute',
  		top:0,
  		bottom:0,
  		backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,.6), rgba(0,0,0,.0))",
     
  	},
  	closeIcon:{
      position : 'absolute',
      top:15,
      right:15,
  	},
    input:{
      marginLeft:10,
      marginRight:10,
    }
};
export default withStyles(styles)(UploadFile);