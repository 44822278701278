import React from 'react';
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import PrivateRoute from './PrivateRoute';
//pages
import { Login,Register } from '../pages/auth';
import { Home,Contact,NotFound } from '../pages/home';
import { CreateStore,ListStore } from '../pages/store';
import { CreateProduct,ListProduct } from '../pages/product';
//components
import Header from '../commons/Header';
import {CircularProgress,Grid} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
//providers
import {createBrowserHistory} from 'history';
import { connect } from 'react-redux';
import { setUser } from '../redux/actions/user';
const history=createBrowserHistory();

class Routing extends React.Component{


  render(){
    const {classes,user}=this.props;    
    return(
        <Router history={history}  >
          <Header {...this.props} history={history}  />   
          {!user.loading ?
          <Switch>
              <Route  exact path="/" render={(props)=><Home user_id={user.user_id} {...props} />} />
              <Route user_id={user.user_id} path="/Contact" component={Contact} />
              <Route user_id={user.user_id} path="/Login" component={Login} />
              <Route user_id={user.user_id} path="/Register" component={Register} />
              <PrivateRoute user_id={user.user_id} authed={user.auth} path="/store/create" Component={CreateStore} />
              <PrivateRoute user_id={user.user_id} authed={user.auth} path="/store/list" Component={ListStore} />
              <PrivateRoute user_id={user.user_id} authed={user.auth} path="/product/create" Component={CreateProduct} />
              <PrivateRoute user_id={user.user_id} authed={user.auth} path="/product/list"  Component={ListProduct} />
              <Route path='*' exact component={NotFound} />
          </Switch>
          :
          <Grid className={classes.container} container justify="center" alignItems="center">
            <CircularProgress />
          </Grid>
          }
        </Router>
    );
  }
}

const styles = {
  container:{
    minHeight:700
  }
};

const stateToProps = state => (state);
const dispatchProps = dispatch => ({
    setUser: (data) => {dispatch(setUser(data))},
});

export default connect(stateToProps,dispatchProps)(withStyles(styles)(Routing));
