import axios from 'axios';


function doLogin(state,setState,props){
	const {history,setUser} =props;
	
	setState({loading:true})
	const params = new FormData();
	params.append('email',state.email);
	params.append('password',state.password);
	axios.post('/auth/login',params)
	.then(function({data}){
		console.log("result",data);
		if(data.error){
			setState({error:data.message});
		}else{
			let user =JSON.stringify(data);
		    localStorage.setItem('user', user);
      		axios.defaults.headers.common['Authorization'] = 'Bearer '+data.token;
			axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
			axios.defaults.params = {};
      		axios.defaults.params['user_id'] = data.id;
		    setUser({auth:true,user:data.first_name+' '+data.last_name})
			history.replace('/')
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	    console.log(error.response.status);
  	    console.log(error.response.headers);
  	  }
  	}).finally(res=>{
  		setState({loading:false});
  	});
}

function doLogout(setState,props){
	setState({loading:true})
	axios.get('/auth/logout')
	.then(function({data}){
		console.log("result",data);
		if(data.error){
			setState({error:data.message});
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	    console.log(error.response.status);
  	    console.log(error.response.headers);
  	  }
  	}).finally(res=>{
  		const { history,setUser } = props;
    	localStorage.removeItem('user');
    	setUser({auth:false,user_id:null,user:null});
  		setState({loading:false});
    	history.replace('/login');
  		
  	});
}

function doRegister(state,setState,history){
	setState({loading:true})
	const params = new FormData();
	params.append('first_name',state.first_name);
	params.append('last_name',state.last_name);
	params.append('address',state.address);
	params.append('city',state.city);
	params.append('zip',state.zip);
	params.append('avatar',state.avatar);
	params.append('birthdate',state.birthdate);
	params.append('gender',state.gender);
	params.append('email',state.email);
	params.append('password',state.password);
	axios.post('/users',params)
	.then(function({data}){
		console.log("result",data);
		if(data.error){
			setState({error:data.message});
		}else{
			history.replace('/login')
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	    console.log(error.response.status);
  	    console.log(error.response.headers);
  	  }
  	}).finally(res=>{
  		setState({loading:false});
  	});
}

function getUsers(state,setState){
	axios.get('/users')
	.then(function(result){
		console.log("result",result);
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	    console.log(error.response.status);
  	    console.log(error.response.headers);
  	  }
  	});
}


export default {
	doLogin,
	doLogout,
	doRegister,
	getUsers
}