import React from 'react';
import { 
  Grid,
  TextField,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Container,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {productService,imageService,storeService} from '../../services';
import { UploadFile } from '../../components';

class Create extends React.Component{

	constructor(props) {
	  super(props);

	  const locationState=this.props.location.state;

	  const store_id =locationState?locationState.store_id:"";
	  this.state={
		name:"",
		description:"",
		price:"",
		discount:"",
		shipping_costs:"",
		shipping_duration:"",
		quantity:"",
		store_id:store_id,
		image1:{},
		image2:{},
		image3:{},
		image4:{},
		image5:{},
		image6:{},
		latitude:48.9,
		longitude:3.00,
		store_list:[],
		loading:false,
		validate:false,
	  }
	}

	componentDidMount(){
		storeService.getByUser((s)=>this.setState(s));
	}


	onChange=(event)=>{
		this.setState({
			[event.target.name]:event.target.value,
		})
	}

	handleSubmit=()=>{
		productService.doCreate(this.state,(s)=>this.setState(s),this.props);
	}

	handleFileUpload=(loading,key,event) =>{
  		imageService.createImage(loading,key,event.target.files[0],(s)=>this.setState(s));
  	}

  	handleFileDelete=(loading,key)=>{
  		imageService.deleteImage(loading,key,this.state,(s)=>this.setState(s));
  	}

	render(){
		const {classes} =this.props;
		return(
  			<Container>				
			<Grid 
				className={classes.main}
				container
    			spacing={0}
    			justify="center"
    			alignItems = 'center'>
    			<Grid 
    				 item lg={12} xs={12}>
    				<Paper
    					className={classes.paper}>
    					<Typography className={classes.title}  variant="h3">Create Product</Typography>
  						<form noValidate autoComplete="off">
  							<Grid 
  								margin='dense'
  								container
  								direction="column">
  								<div className={classes.gallery} >
  								<UploadFile 
  									className={classes.file}
  									name="image1" 
  									error={this.state.validate && !this.state.image1.id?true:false}
  									handleFileUpload={(loading,event)=>this.handleFileUpload(loading,'image1',event)}
									handleFileDelete={(loading)=>this.handleFileDelete(loading,'image1')}
  									state={this.state} />
  								<UploadFile 
  									className={classes.file}
  									name="image2"
  									error={this.state.validate && !this.state.image2.id?true:false}
  									handleFileUpload={(loading,event)=>this.handleFileUpload(loading,'image2',event)}
									handleFileDelete={(loading)=>this.handleFileDelete(loading,'image2')}
  									state={this.state} />
  								<UploadFile 
  									className={classes.file}
  									name="image3"
  									error={this.state.validate && !this.state.image3.id?true:false}
  									handleFileUpload={(loading,event)=>this.handleFileUpload(loading,'image3',event)}
									handleFileDelete={(loading)=>this.handleFileDelete(loading,'image3')}
  									state={this.state} />
  								<UploadFile 
  									className={classes.file}
  									name="image4" 
  									error={this.state.validate && !this.state.image4.id?true:false}
  									handleFileUpload={(loading,event)=>this.handleFileUpload(loading,'image4',event)}
									handleFileDelete={(loading)=>this.handleFileDelete(loading,'image4')}
  									state={this.state} />
  								<UploadFile 
  									className={classes.file}
  									name="image5"
  									error={this.state.validate && !this.state.image5.id?true:false}
  									handleFileUpload={(loading,event)=>this.handleFileUpload(loading,'image5',event)}
									handleFileDelete={(loading)=>this.handleFileDelete(loading,'image5')}
  									state={this.state} />
  								<UploadFile 
  									className={classes.file}
  									name="image6"
  									error={this.state.validate && !this.state.image6.id?true:false}
  									handleFileUpload={(loading,event)=>this.handleFileUpload(loading,'image6',event)}
									handleFileDelete={(loading)=>this.handleFileDelete(loading,'image6')}
  									state={this.state} />
  								</div>
  								<FormControl 
							  		variant="outlined" 
							  		className={classes.input}>
      							  <InputLabel className={classes.labelStyle} id="store-select">Store</InputLabel>
      							  <Select
      							  	error={this.state.validate && !this.state.store_id?true:false}
      							    labelId="store-select"
      							    id="demo-customized-select"
      							    value={this.state.store_id}
      							    name="store_id"
      							    onChange={this.onChange}
      							  >
      							    <MenuItem value={""}>
      							      <em>None</em>
      							    </MenuItem>
      							    {this.state.store_list.map((store,index)=>(
      							    	<MenuItem key={store.id} value={store.id}>{store.name}</MenuItem>
      							    ))}
      							  </Select>
      							</FormControl>
							  	<TextField 
							  		error={this.state.validate && !this.state.name?true:false}
							  		value={this.state.name}
							  		name="name"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Name" 
							  		variant="outlined" />
							  	<TextField 
							  		error={this.state.validate && !this.state.description?true:false}
							  		multiline={true}
							  		rows={6}
							  		value={this.state.description}
							  		name="description"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Description" 
							  		variant="outlined" />
							  	<TextField 
							  		type="number"
							  		error={this.state.validate && !this.state.quantity?true:false}
							  		value={this.state.quantity}
							  		name="quantity"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Quantity in stock" 
							  		variant="outlined" />
							  	<TextField 
							  		type="number"
							  		error={this.state.validate && !this.state.price?true:false}
							  		value={this.state.price}
							  		name="price"
							  		onChange={this.onChange}
							  		InputProps={{  endAdornment: <InputAdornment position="end">€</InputAdornment>}}
							  		className={classes.input} 
							  		label="Price" 
							  		variant="outlined" />
							  	<TextField 
							  		type="number"
							  		value={this.state.discount}
							  		name="discount"
							  		onChange={this.onChange}
							  		InputProps={{  endAdornment: <InputAdornment position="end">€</InputAdornment>}}
							  		className={classes.input} 
							  		label="Discount" 
							  		variant="outlined" />
							  	<TextField 
							  		type="number"
							  		error={this.state.validate && !this.state.shipping_costs?true:false}
							  		value={this.state.shipping_costs}
							  		name="shipping_costs"
							  		onChange={this.onChange}
							  		InputProps={{  endAdornment: <InputAdornment position="end">€</InputAdornment>}}
							  		className={classes.input} 
							  		label="Shipping costs" 
							  		variant="outlined" />
							  	<TextField 
							  		type="number"
							  		error={this.state.validate && !this.state.shipping_duration?true:false}
							  		value={this.state.shipping_duration}
							  		name="shipping_duration"
							  		onChange={this.onChange}
							  		InputProps={{  endAdornment: <InputAdornment position="end">hours</InputAdornment>}}
							  		className={classes.input} 
							  		label="Shipping duration" 
							  		variant="outlined" />
								<Typography color={"secondary"} >{this.state.error}</Typography>
								<Button 
									onClick={this.handleSubmit}
									className={classes.btn} variant="contained" 
									size="large" color="primary">
									{!this.state.loading ? 
									<Typography>Create</Typography>
									:<CircularProgress size={25} color="secondary" />}
								</Button>
							</Grid>
						</form>
					</Paper>
				</Grid>
  			</Grid> 
  			</Container>				

		);
	}
}

const styles = {
	main:{
		minHeight:700,
		paddingTop:60,
		paddingBottom:60,
	},
	paper:{	
		paddingTop:60,
		paddingBottom:60,
		paddingLeft:30,
		paddingRight:30,
	},
	input:{
		marginBottom:20
	},
  	btn:{
  		marginTop : 20,
  	},
  	title:{
  		marginBottom : 40,
  		textAlign : 'center'
  	},
  	labelStyle:{
  		backgroundColor:"white",
  		paddingLeft:3,
		paddingRight:3,
  	},
  	gallery:{
  		display:"flex",
  		flexDirection : 'row',
  		justifyContent:"space-between",
  		flexWrap : 'wrap'
  	},
  	file:{
  		width:"auto"
  	}
  	
};

export default withStyles(styles)(Create);

