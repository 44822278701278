import axios from 'axios';

function createImage(loading,key,file,setState){
	
	loading(true)
	const params = new FormData();
  params.append('file',file);
	params.append('type',key);
	axios.post('/image/create',params)
	.then(function({data}){
		console.log("result",data);
		if(data.error){
			setState({error:data.message});
		}else{
			//fetchImage(data.path,setState)
			setState({[key]:data})
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	    console.log(error.response.status);
  	    console.log(error.response.headers);
  	  }
  	}).finally(res=>{
  		loading(false);
  	});
}


function deleteImage(loading,key,state,setState){
	
	loading(true)
	axios.delete('/image/'+state[key].id)
	.then(function({data}){
		console.log("result",data);
		if(data.error){
			setState({error:data.message});
		}else{
			setState({[key]:{}});
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	    console.log(error.response.status);
  	    console.log(error.response.headers);
  	  }
  	}).finally(res=>{
  		loading(false);
  	});
}

function fetchImage(url,setState){
	axios.get(url)
	.then(response => response.blob()) // sending the blob response to the next then
    .then(blob => {
        const objectUrl = URL.createObjectURL(blob);
        setState({image:objectUrl})
    })
    .catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	    console.log(error.response.status);
  	    console.log(error.response.headers);
  	  }
  	}).finally(res=>{
  		setState({file_loading:false});
  	});
}



export default {
	createImage,
	deleteImage
}