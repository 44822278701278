import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  Grid,
  List as ListView,
  Typography,
  Container,
  Button
} from '@material-ui/core';
import {productService} from '../../services';
import {ProductItem}from '../../components';

class List extends React.Component{

	state={
		list:[],

	}
	componentWillMount(){
		//if(this.props.location.state){
		//	const store_id=this.props.location.state.store_id;
		//	console.log("store_id",store_id);
		//	productService.getByStore(store_id,(s)=>this.setState(s),this.props);
		//}else{
			productService.getByUser((s)=>this.setState(s));
		//}
	}

	goCreate=()=>{
		const {history}= this.props;
		const locationState=this.props.location.state;
		if(locationState){
			history.push('/product/create',{store_id:locationState.store_id});
		}else{
			history.push('/product/create');
		}
	}

	goProduct=()=>{

	}

	render(){
		const {classes,user_id} =this.props;
		return(
			<Container  className={classes.root}>
				<Grid container justify="space-between" >
					<Typography
            		  	variant="h3"
            		  	color="textPrimary"
            		  	align="center">
            		  	My products
            		</Typography>
            		<Button 
            			onClick={this.goCreate}
            			variant="outlined" size='large' color="primary">
      				  	Create product
      				</Button>
            	</Grid>
				<ListView className={classes.list}>
      				<Grid container spacing={3} >
						{this.state.list.map((item,index)=>(
      						<Grid 
      							item xs={12} sm={6} md={4}  
      							key={index} onClick={()=>this.goProduct(item.id)}  >
      							<ProductItem 
                  					listKey="list"
      								item={item}
      								index={index} 
      								user_id={user_id} 
      								setState={(s)=>this.setState(s)} />
      						</Grid>
      					))}
      				</Grid>
      			</ListView>
      		</Container>
		);
	}
}

const styles = {
	root:{
		minHeight:700,
		paddingTop:60
	},
	list:{
		marginTop:40
	},
	
};
export default withStyles(styles)(List);