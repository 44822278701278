import React from 'react';
import './App.css';
import Routing from './routes/Routing';
import configureStore from './redux/store';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
const store = configureStore();

function App() {
	const theme = createMuiTheme({
	  palette: {
	    primary: {
	      main: "#4Ecdc4",
	    },
	    
	  },
	});
  	return (
  	  <div className="App">
  	  	<ThemeProvider theme={theme}>
  	  		<Provider store={store}>
  	  		  <Routing />
  	  		</Provider>
  	  	</ThemeProvider>
  	  </div>
  	);
}

export default App;


