import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  Container,
  Typography,
  Grid,
  List,
  CircularProgress
} from '@material-ui/core';
import {globalService}  from '../../services';
import {StoreItem,ProductItem}from '../../components';

class Home extends React.Component{

	state={
		stores:[],
		products:[],
    loading:true
	}

	componentDidMount(){
    const {user_id } = this.props;
		globalService.getHome(user_id,(s)=>this.setState(s));
	}

  goProduct=()=>{

  }

	render(){
		const {classes,user_id,history} =this.props;
		return(
			<Container className={classes.main}>
				{!this.state.loading?
        <Grid>
        <Typography
          variant="h3"
          color="textPrimary">
          Stores
        </Typography>
				<List className={classes.list}>
      		<Grid container spacing={3} >
				{this.state.stores.map((item,index)=>(
      				<Grid item xs={12} sm={6} md={4}  key={index} onClick={()=>this.goProduct(item.id)}  >
      					<StoreItem item={item} />
      				</Grid>
      			))}
      		</Grid>
      	</List>

      	<Typography
          	variant="h3"
          	color="textPrimary">
          	Products
        </Typography>
				<List className={classes.list}>
      		<Grid container spacing={3} >
				{this.state.products.map((item,index)=>(
      				<Grid item xs={12} sm={6} md={4}  key={index} onClick={()=>this.goProduct(item.id)}  >
      					<ProductItem 
                  listKey="products"
                  history={history}
                  item={item}
                  index={index} 
                  user_id={user_id} 
                  setState={(s)=>this.setState(s)} />
      				</Grid>
      			))}
      		</Grid>
      	</List>
        </Grid>
        :<Grid className={classes.main} container justify="center" alignItems="center">
          <CircularProgress />
        </Grid>}
			</Container>
		);
	}
}

const styles = {
	main:{
		minHeight:700,
	},
	bgImage:{
		width:"100%"
	}
};
export default withStyles(styles)(Home);