import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  Grid,
  List as ListView,
  Typography,
  Container,
  Button
} from '@material-ui/core';
import {storeService} from '../../services';
import {StoreItem}from '../../components'

class List extends React.Component{

	state={
		store_list:[],

	}
	componentDidMount(){
		storeService.getByUser((s)=>this.setState(s));
	}

	goProduct=(id)=>{
		//const {history}= this.props;
		//history.push("/product/list",{store_id:id});
	}
	render(){
		const {classes,history} =this.props;
		return(
			<Container  className={classes.root}>
				<Grid container justify="space-between" >
					<Typography
            		  	variant="h3"
            		  	color="textPrimary"
            		  	align="center">
            		  	My stores
            		</Typography>
            		<Button 
            			onClick={()=>history.push('/store/create')}
            			variant="outlined" size='large' color="primary">
      				  	Create store
      				</Button>
            	</Grid>
				<ListView className={classes.store_list}>
      				<Grid container spacing={3} >
						{this.state.store_list.map((item,index)=>(
      						<Grid item xs={12} sm={6} md={4}  key={index} onClick={()=>this.goProduct(item.id)}  >
      							<StoreItem item={item} />
      						</Grid>
      					))}
      				</Grid>
      			</ListView>
      		</Container>
		);
	}
}

const styles = {
	root:{
		minHeight:700,
		paddingTop:60
	},
	store_list:{
		marginTop:40
	},
	
};
export default withStyles(styles)(List);