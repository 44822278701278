import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  Card,
  CardMedia,
  CardContent,
  Typography,
  Avatar
} from '@material-ui/core';


class StoreItem extends React.Component{
	render(){
		const {classes,item} =this.props;
		return(
      <Card variant="outlined" className={classes.card}>
			  <CardMedia
          className={classes.media}
          image={item.image}
          title={item.name}
        />
        <CardContent className={classes.cardContent} >
          <div className={classes.user}>
            <Avatar 
              className={classes.avatar}
              alt={item.first_name.toUpperCase()+' '+item.last_name} 
              src={classes.avatar}  />
            <div>
              <Typography
                variant="h5"
                color="textPrimary">
                {item.first_name+' '+item.last_name}
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary">
                {item.address+' '+item.city+' '+item.zip}
              </Typography>
            </div>
          </div>
           <Typography
            variant="h6"
            color="textPrimary">
            {item.name}
          </Typography>
          <Typography
            className={classes.description}
            variant="subtitle1"
            color="textSecondary">
            {item.description}
          </Typography>
        </CardContent>
      </Card>
		);
	}
}

const styles = {
  card:{
    borderRadius:5,
    marginBottom:30,

  },
  cardContent:{
    padding:15
  },
  media:{
    width:"100%",
    height:250,
    backgroundColor:"rgba(0,0,0,.1)"
    
  },
  user:{
    display: 'flex',
    flexDirection : 'row',
    alignItems:"center",
    marginBottom:15,

  },
  avatar:{
    width:60,
    height:60,
    marginRight:10,
    backgroundColor:"rgba(0,0,0,.1)"
  },
  description:{
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
};
export default withStyles(styles)(StoreItem);