import React from 'react';
import { 
  Grid,
  TextField,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Container,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {storeService,imageService} from '../../services';
import { UploadFile } from '../../components';

class Create extends React.Component{

	state={
		name:"",
  		description:"",
  		address:"",
  		city:"",
  		zip:"",
		image:{},
		latitude:48.9,
		longitude:3.00,
		loading:false,
		validate:false,
	}

	onChange=(event)=>{
		this.setState({
			[event.target.name]:event.target.value,
		})
	}

	handleSubmit=()=>{
		storeService.doCreate(this.state,(s)=>this.setState(s),this.props);
	}

	handleFileUpload=(loading,event) =>{
  		imageService.createImage(loading,'image',event.target.files[0],(s)=>this.setState(s));
  	}

  	handleFileDelete=(loading)=>{
  		imageService.deleteImage(loading,'image',this.state,(s)=>this.setState(s));
  	}

	render(){
		const {classes} =this.props;
		return(
  			<Container>				
			<Grid 
				className={classes.main}
				container
    			spacing={0}
    			justify="center"
    			alignItems = 'center'>
    			<Grid 
    				 item lg={5} xs={12}>
    				<Paper
    					className={classes.paper}>
    					<Typography className={classes.title}  variant="h3">Create Store</Typography>
  						<form noValidate autoComplete="off">
  							<Grid 
  								margin='dense'
  								container
  								direction="column">
  								<UploadFile 
  									name="image" 
  									error={this.state.validate && !this.state.image.id?true:false}
  									handleFileUpload={(loading,event)=>this.handleFileUpload(loading,event)}
									handleFileDelete={(loading)=>this.handleFileDelete(loading)}
  									state={this.state} />
							  	<TextField 
							  		error={this.state.validate && !this.state.name?true:false}
							  		value={this.state.name}
							  		name="name"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Store Name" 
							  		variant="outlined" />
							  	<TextField 
							  		error={this.state.validate && !this.state.description?true:false}
							  		multiline={true}
							  		rows={3}
							  		value={this.state.description}
							  		name="description"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Description" 
							  		variant="outlined" />
							  	<TextField 
							  		error={this.state.validate && !this.state.address?true:false}
							  		value={this.state.address}
							  		name="address"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Store Address" 
							  		variant="outlined" />
							  	<TextField 
							  		error={this.state.validate && !this.state.city?true:false}
							  		value={this.state.city}
							  		name="city"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Store City" 
							  		variant="outlined" />
							  	<TextField 
							  		error={this.state.validate && !this.state.zip?true:false}
							  		value={this.state.zip}
							  		name="zip"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Store Zip code" 
							  		variant="outlined" />
								<Typography color={"secondary"} >{this.state.error}</Typography>
								<Button 
									onClick={this.handleSubmit}
									className={classes.btn} variant="contained" 
									size="large" color="primary">
									{!this.state.loading ? 
									<Typography>Create</Typography>
									:<CircularProgress size={25} color="secondary" />}
								</Button>
							</Grid>
						</form>
					</Paper>
				</Grid>
  			</Grid> 
  			</Container>				

		);
	}
}

const styles = {
	main:{
		minHeight:700,
		paddingTop:60,
		paddingBottom:60,
	},
	paper:{	
		paddingTop:60,
		paddingBottom:60,
		paddingLeft:30,
		paddingRight:30,
	},
	input:{
		marginBottom:20
	},
  	btn:{
  		marginTop : 20
  	},
  	title:{
  		marginBottom : 40,
  		textAlign : 'center'
  	},
  	labelStyle:{
  		backgroundColor:"white",
  		paddingLeft:3,
		paddingRight:3,
  	},
  	
};

export default withStyles(styles)(Create);

