import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  Card,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import {FavoriteBorder,Favorite} from '@material-ui/icons';
import {productService} from '../services';
import Review from './Review'

class ProductItem extends React.Component{

  handleLike= ()=>{
    const {item,user_id,setState,index,history,listKey} =this.props;
    if(user_id){
      productService.doLike(index,item.id,user_id,setState,listKey);    
    }else{
      history.push('/login');
    }
  }

  handleDislike= ()=>{
    const {item,user_id,setState,index,history,listKey} =this.props;
    if(user_id){
      productService.doDislike(index,item.id,user_id,setState,listKey);
    }else{
      history.push('/login');
    }
  }

	render(){
		const {classes,item} =this.props;
		return(
      <Card variant="outlined" className={classes.card}>
			  <CardMedia
          className={classes.media}
          image={item.image}
          title={item.product_name}>
          <div className={classes.innerLinear}  >
          {item.is_favorited?
            <Favorite
              onClick={this.handleDislike}
              className={classes.favoriteIcon} size={35}/>
            :
            <FavoriteBorder 
              onClick={this.handleLike}
              className={classes.favoriteBorderIcon} size={35}/>
          }
          </div>
        </CardMedia>
        <CardContent className={classes.cardContent} >
          <div className={classes.product}>
            <div>
              <Typography
                variant="h6"
                color="textPrimary">
                {item.product_name}
              </Typography>
              <Review rating={item.rating} rating_count={item.rating_count} />
            </div>
            <div>
              <Typography
                className={classes.currentPrice}
                variant="h4"
                color="textPrimary">
                {(item.price-item.discount)+'€'  }‎
              </Typography>
              {item.discount>0 &&<Typography
                className={classes.oldPrice}
                variant="h6"
                color="textPrimary">
                {item.price+'€' }‎
              </Typography>}
            </div>
          </div>
          <Typography
            className={classes.description}
            variant="subtitle1"
            color="textSecondary">
            {item.description}
          </Typography>
        </CardContent>
      </Card>
		);
	}
}

const styles = {
  card:{
    borderRadius:5,
    marginBottom:30,

  },
  cardContent:{
    padding:15
  },
  media:{
    width:"100%",
    height:250,
    backgroundColor:"rgba(0,0,0,.1)",
    position:"relative"
  },
  innerLinear:{
      position : 'absolute',
      top:0,
      bottom:0,
      right:0,
      left:0,
      backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,.8), rgba(0,0,0,.2), rgba(0,0,0,.0))",
  },
  favoriteIcon:{
    position : 'absolute',
    top:15,
    right:15,
    color:"red"
  },
  favoriteBorderIcon:{
    position : 'absolute',
    top:15,
    right:15,
    color:"white"
  },
  product:{
    display: 'flex',
    flexDirection : 'row',
    justifyContent:"space-between",
  },
  avatar:{
    width:60,
    height:60,
    marginRight:10,
    backgroundColor:"rgba(0,0,0,.1)"
  },
  description:{
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  oldPrice:{
    textDecoration :'line-through',
    color:"rgba(0,0,0,.3)"
  },
  currentPrice:{
    fontSize:30,
    lineHeight:1
  }
};
export default withStyles(styles)(ProductItem);