import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  Grid,
  Typography
} from '@material-ui/core';
import { Grade,GradeOutlined } from '@material-ui/icons';


class Review extends React.Component{
  state={
    loading:false,
    number:[1,2,3,4,5]
  }
	render(){
		const {className,classes,rating,rating_count} =this.props;
    const style=`${classes.container} ${className}`;
		return(
			<Grid container className={style} > 
        {this.state.number.map((rate,index)=>{
          if(rating && rating >= rate)
          {
            return (<Grade key={index} className={classes.rate} />);
          }else{
            return (<GradeOutlined key={index} className={classes.rate}  />  );
          }
        })}
        <Typography variant="h6" >{'('+rating_count+')'}</Typography>
			</Grid>
		);
	}
}

const styles = {
  container:{
    alignItems : 'center',
  },
  rate:{
    color:"#fbd549",
    fontSize:26
  },
  text:{
    fontSize:18
  }
};
export default withStyles(styles)(Review);