import axios from 'axios';


function doCreate(state,setState,props){
	const {history} =props;
	
	setState({loading:true,validate:true})
	const params = new FormData();
	params.append('name',state.name);
	params.append('description',state.description);
	params.append('address',state.address);
	params.append('city',state.city);
	params.append('zip',state.zip);
	params.append('image_id',state.image.id);
	params.append('latitude',state.latitude);
	params.append('longitude',state.longitude);
	console.log("state",state);
	axios.post('/store/create',params)
	.then(function({data}){
		console.log("result",data);
		if(data.error){
			setState({error:data.message});
		}else{
			history.replace('/store/list')
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	    console.log(error.response.status);
  	    console.log(error.response.headers);
  	  }
  	}).finally(res=>{
  		setState({loading:false});
  	});
}

function getByUser(setState){
	setState({loading:true})
	axios.get('/store/list')
	.then(function({data}){
		console.log("result",data);
		if(data.error){
			setState({error:"You have to create store first "});
		}else{
			setState({store_list:data});
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	  }
  	}).finally(res=>{
  		setState({loading:false});
  	});
}


export default {
	doCreate,
	getByUser
}