import React from 'react';
import { 
  Grid,
  TextField,
  Paper,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import userService from '../../services/user';
import moment from 'moment';

class Register extends React.Component{

	state={
		email:"",
  		first_name:"",
  		last_name:"",
  		address:"",
  		city:"",
  		zip:"",
  		birthdate:moment().subtract(18,'years').format("YYYY-MM-DD"),
  		gender:1,
  		password:"",
		loading:false
	}

	onChange=(event)=>{
		this.setState({
			[event.target.name]:event.target.value,
		})
	}

	handleSubmit=()=>{
		const {history} =this.props;
		userService.doRegister(this.state,(s)=>this.setState(s),history);
	}

	render(){
		const {classes} =this.props;
		return(
  			<Container>				
			<Grid 
				className={classes.main}
				container
    			spacing={0}
    			justify="center"
    			alignItems = 'center'>
    			<Grid 
    				 item lg={5} xs={12}>
    				<Paper
    					className={classes.paper}>
    					<Typography className={classes.title}  variant="h3">Register</Typography>
  						<form noValidate autoComplete="off">
  							<Grid 
  								margin='dense'
  								container
  								direction="column">
  								<TextField 
							  		value={this.state.first_name}
							  		name="first_name"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="First Name" 
							  		variant="outlined" />
							  	<TextField 
							  		value={this.state.last_name}
							  		name="last_name"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Last Name" 
							  		variant="outlined" />
							  	<TextField 
							  		value={this.state.address}
							  		name="address"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Address" 
							  		variant="outlined" />
							  	<TextField 
							  		value={this.state.city}
							  		name="city"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="City" 
							  		variant="outlined" />
							  	<TextField 
							  		value={this.state.zip}
							  		name="zip"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Zip code" 
							  		variant="outlined" />
							  	<TextField 
							  		type="date"
							  		value={this.state.birthdate}
							  		name="birthdate"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Birth date" 
							  		variant="outlined" />
							  	<FormControl 
							  		variant="outlined" 
							  		className={classes.input}>
      							  <InputLabel className={classes.labelStyle} id="age-select">Age</InputLabel>
      							  <Select
      							    labelId="age-select"
      							    id="demo-customized-select"
      							    value={this.state.gender}
      							    name="gender"
      							    onChange={this.onChange}
      							  >
      							    <MenuItem value="">
      							      <em>None</em>
      							    </MenuItem>
      							    <MenuItem value={1}>Male</MenuItem>
      							    <MenuItem value={2}>Female</MenuItem>
      							  </Select>
      							</FormControl>
		
							  	<TextField 
							  		value={this.state.email}
							  		name="email"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Email" 
							  		variant="outlined" />
							  	<TextField 
							  		type="password"
							  		value={this.state.password}
							  		name="password"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Password" 
							  		variant="outlined" />
								<Typography color={"secondary"} >{this.state.error}</Typography>
								<Button 
									onClick={this.handleSubmit}
									className={classes.btn} variant="contained" 
									size="large" color="primary">
									{!this.state.loading ? 
									<Typography>Register</Typography>
									:<CircularProgress size={25} color="secondary" />}
								</Button>
							</Grid>
						</form>
					</Paper>
				</Grid>
  			</Grid> 
  			</Container>				

		);
	}
}

const styles = {
	main:{
		minHeight:700,
		paddingTop:60,
		paddingBottom:60,
	},
	paper:{	
		paddingTop:60,
		paddingBottom:60,
		paddingLeft:30,
		paddingRight:30,
	},
	input:{
		marginBottom:20
	},
  	btn:{
  		marginTop : 20
  	},
  	title:{
  		marginBottom : 40,
  		textAlign : 'center'
  	},
  	labelStyle:{
  		backgroundColor:"white",
  		paddingLeft:3,
		paddingRight:3,
  	}
};

export default withStyles(styles)(Register);

