import React from 'react';
import {
  Route,
  Redirect
} from "react-router-dom";

export class PrivateRoute extends React.Component {
	render(){
		const {Component, rest ,authed,user_id } =this.props;
  		return (
  		  <Route
  		    {...rest}
  		    render={(props) => authed === true
  		      ? <Component  {...props} user_id={user_id}  />
  		      : <Redirect  to={{pathname: '/login', state: {from: props.location}}} />}
  		  />
  		)
	}
}

export default PrivateRoute;