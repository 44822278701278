import React from 'react';
import { 
  Container,
  AppBar,
  Toolbar,
  Link,
  Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { mainService,userService } from '../services';


class Header extends React.Component{

  componentDidMount(){
    mainService.initialize(this.props);
  }

  handleLogout=()=>{
    userService.doLogout((s)=>this.setState(s),this.props)
  }

  render(){
    const { auth,user } = this.props.user;
    const { classes } = this.props;
    return(
      <AppBar position="static" classes={{root:classes.root,colorPrimary:classes.colorPrimary}}>
        <Toolbar>
          <Container >
          <Grid 
            container 
            justify="flex-end"  >
            {auth ? 
            <Grid >
              <Link className={classes.link} href="/">
                Home
              </Link>
              <Link className={classes.link} href="/product/list">
                My Products
              </Link>
              <Link className={classes.link} href="/store/list">
                My Stores
              </Link>
              <Link className={classes.link} href="/">
                {user}
              </Link>
              <Link className={classes.link} href="#logout" onClick={this.handleLogout}>
                Logout
              </Link>
            </Grid>
            :
            <Grid >
              <Link className={classes.link} href="/">
                Home
              </Link>
              <Link className={classes.link} href="/Login">
                Login
              </Link>
              <Link href="/Register">
                Sign up
              </Link>
            </Grid>
            }
          </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    );
  }
}

const styles = {
  root:{
    boxShadow: "-3px -2px 20px 0px rgba(0, 0, 0, .1)"
  },
  colorPrimary: {
    backgroundColor:"#fff",
    color:"#000"
  },
  link:{
    marginRight:15
  },
  container:{
    justifyContent:"flex-end"
  }
};


export default withStyles(styles)(Header);
