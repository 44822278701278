import React from 'react';
import { 
  Grid,
  TextField,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Container
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import userService from '../../services/user';
import { connect } from 'react-redux';
import { setUser } from '../../redux/actions/user'

class Login extends React.Component{

	state={
		email:"",
		password:"",
		loading:false
	}

	onChange=(event)=>{
		this.setState({
			[event.target.name]:event.target.value,
		})
	}

	handleSubmit=()=>{
		userService.doLogin(this.state,(s)=>this.setState(s),this.props);
	}

	render(){
		const {classes} =this.props;
		return(
			<Container>
			<Grid 
				className={classes.main}
				container
    			spacing={0}
    			justify="center"
    			alignItems = 'center'>
    			<Grid 
    				 item lg={5} xs={12}>
    				<Paper
    					className={classes.paper}>
    					<Typography className={classes.title}  variant="h3">Login</Typography>
  						<form noValidate autoComplete="off">
  							<Grid 
  								margin='dense'
  								container
  								direction="column">
							  	<TextField 
							  		value={this.state.email}
							  		name="email"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Email" 
							  		variant="outlined" />
							  	<TextField 
							  		type="password"
							  		value={this.state.password}
							  		name="password"
							  		onChange={this.onChange}
							  		className={classes.input} 
							  		label="Password" 
							  		variant="outlined" />
								<Typography color={"secondary"} >{this.state.error}</Typography>
								<Button 
									onClick={this.handleSubmit}
									className={classes.btn} variant="contained" 
									size="large" color="primary">
									{!this.state.loading ? 
									<Typography>Login</Typography>
									:<CircularProgress size={25} color="secondary" />}
								</Button>
							</Grid>
						</form>
					</Paper>
				</Grid>
  			</Grid>  
  			</Container>				
		);
	}
}

const styles = {
	main:{
		height:700,
	},
	paper:{	
		paddingTop:60,
		paddingBottom:60,
		paddingLeft:30,
		paddingRight:30,
	},
	input:{
		marginBottom:20
	},
  	btn:{
  		marginTop : 20
  	},
  	title:{
  		marginBottom : 40,
  		textAlign : 'center'
  	}
};

const stateToProps = state => (state);
const dispatchProps = dispatch => ({
    setUser: (data) => {dispatch(setUser(data))},
});

export default connect(stateToProps,dispatchProps)(withStyles(styles)(Login));

