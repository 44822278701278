import axios from 'axios';


function doCreate(state,setState,props){
	const {history} =props;
	setState({loading:true,validate:true})
	const params = new FormData();
	params.append('store_id',state.store_id);
	params.append('name',state.name);
	params.append('price',state.price);
	params.append('discount',state.discount?state.discount:0);
	params.append('shipping_costs',state.shipping_costs);
	params.append('shipping_duration',state.shipping_duration);
	params.append('quantity',state.quantity);
	params.append('description',state.description);
	if(state.image1.id){
		params.append('image_id[]',state.image1.id);
	}
	if(state.image2.id){
		params.append('image_id[]',state.image2.id);
	}
	if(state.image3.id){
		params.append('image_id[]',state.image3.id);
	}
	if(state.image4.id){
		params.append('image_id[]',state.image4.id);
	}
	if(state.image5.id){
		params.append('image_id[]',state.image5.id);
	}
	axios.post('/product/create',params)
	.then(function({data}){
		console.log("result",data);
		if(data.error){
			setState({error:data.message});
		}else{
			history.replace('/product/list',{store_id:state.store_id})
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	    console.log(error.response.status);
  	    console.log(error.response.headers);
  	  }
  	}).finally(res=>{
  		setState({loading:false});
  	});
}

function getByStore(store_id,setState,props){
	setState({loading:true})
	const user_id=props.user_id;
	axios.get('/store/products/'+store_id+'/'+user_id)
	.then(function({data}){
		console.log("result",data);
		if(data.error){
			setState({error:data.message});
		}else{
			setState({list:data});
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	  }
  	}).finally(res=>{
  		setState({loading:false});
  	});
}


function getByUser(setState){
	setState({loading:true})
	axios.get('/user/products')
	.then(function({data}){
		console.log("result",data);
		if(data.error){
			setState({error:data.message});
		}else{
			setState({list:data});
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	  }
  	}).finally(res=>{
  		setState({loading:false});
  	});
}


function getAll(setState,props){
	setState({loading:true})
	const user_id=props.user_id;
	console.log("props",user_id)
	axios.get('/products/'+user_id)
	.then(function({data}){
		console.log("result",data);
		if(data.error){
			setState({error:data.message});
		}else{
			setState({list:data});
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	  }
  	}).finally(res=>{
  		setState({loading:false});
  	});
}

function doLike(index,product_id,user_id,setState,listKey){
	setState({loading:true})
	const params = new FormData();
	params.append('product_id',product_id);
	params.append('user_id',user_id);
	axios.post('/product/like',params)
	.then(function({data}){
		console.log("result",data);
		if(data.success){
			setState(prevState=>{
				prevState[listKey][index].is_favorited=true;
				return prevState;
			})
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	  }
  	}).finally(res=>{
  		setState({loading:false});
  	});
}

function doDislike(index,product_id,user_id,setState,listKey){
	setState({loading:true})
	const params = new FormData();
	params.append('product_id',product_id);
	params.append('user_id',user_id);
	axios.post('/product/dislike',params)
	.then(function({data}){
		console.log("result",data);
		if(data.success){
			setState(prevState=>{
				prevState[listKey][index].is_favorited=false;
				return prevState;
			})
		}
	})
  	.catch(function (error) {
  	  if (error.response) {
  	    console.log(error.response.data);
  	  }
  	}).finally(res=>{
  		setState({loading:false});
  	});
}

export default {
	doCreate,
	getByStore,
	getAll,
	doLike,
	doDislike,
	getByUser
}