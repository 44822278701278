import axios from 'axios';

function getHome(user_id,setState){
  setState({loading:true})
  console.log("user_id",user_id)
  axios.get('/home/'+user_id)
  .then(function({data}){
    console.log("result",data);
    if(data.error){
      setState({error:data.message});
    }else{
      setState({...data});
    }
  })
  .catch(function (error) {
    if (error.response) {
      console.log(error.response.data);
    }
  }).finally(res=>{
    setState({loading:false});
  });
}

export default {
	getHome,
}