import axios from 'axios';
import { BASE_URL } from '../config/keys';

function initialize(props){
	axios.defaults.baseURL = BASE_URL;
	let user=localStorage.getItem('user');
    if(user){
      let jsonUser=JSON.parse(user);
      props.setUser({auth:true,user_id:jsonUser.id,user:jsonUser.first_name+' '+jsonUser.last_name,loading:false});
      axios.defaults.headers.common['Authorization'] = 'Bearer '+jsonUser.token;
		  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    }else{
      	props.setUser({loading:false});
    }
	
}

export default {
	initialize,
}