const initialState = {
  auth:false,
  user:null,
  user_id:null,
  loading:true
};

const userReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_USER':
      return {
        ...state,
        ...action.payload 
      };
    default:
      return state;
  }
}

export default userReducer;